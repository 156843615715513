/* Estilos generales */
.our-values {
    text-align: center;
    padding: 2rem;
    /*background-color: #f8f9fa; !* Color de fondo claro *!*/
}

h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
}

/* Estilos para las tarjetas de valores */
.contenedor-valores {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px; /* Espaciado entre tarjetas */
    padding: 1rem;
}

.valores {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.valores:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Estilos para los encabezados de los valores */
.valores h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--container-color-blue); /* Un color que resalte para los títulos */
    text-transform: uppercase;
}

/* Estilos para el contenido */
.valores p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin: 0;
}

/* Para adaptarse bien a pantallas pequeñas */
@media (max-width: 768px) {
    .container-valores {
        grid-template-columns: 1fr;
    }
}