:root{
    --container-color-green: #9CDBC8 ;
    --container-color-blue:#84A7F6;
    --container-color-yellow: #F6CC84;
    --container-color-purple: #BD84F6;
}


.activities{
    display: flex;
    flex-direction: column;
    max-width: 90%;
}

.activity-container-izq, .activity-container-der{
    display: flex;
}

.activity-container-izq {
    flex-direction: row;
    align-self: flex-start;
}

.activity-container-der {
    flex-direction: row-reverse;
    align-self: flex-end;
}

.activity-img{
    max-width: 35%;
    margin: 1vh;
    border-radius: 10px;
}

/* Estilos comunes para todos los containers */
[class^="container-"] {
    padding: 20px;
    border-radius: 10px;
    margin: 1vh;
    width: 60%;
}

/* Estilos específicos según el color de la clase */
.container-green {
    background-color: var(--container-color-green);
}

.container-blue {
    background-color: var(--container-color-blue);
}

.container-yellow {
    background-color: var(--container-color-yellow);
}

.container-purple{
    background-color: var(--container-color-purple);
}

@media (max-width: 786px) {
    [class^="container-"] {
        width: 100%;
    }

}