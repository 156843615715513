/* Animación de desvanecimiento (fade) */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 0;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-out;
}
