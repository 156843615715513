body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgb(0, 0, 0);
  background-color: var(--background-color);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --header-background-color: #D2E0FB ;
  --nav-background-color:#FEF9D9;
  --nav-hover-background-color: #c2ba92;
  --background-color: #FFFFFF;
  --footer-background-color: #8EACCD;
}

#root {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70vw ;
}
#logo{
  border: 0;
  width: 55%;
  margin-left:10% ;
}

.main-content{
  align-items: center;
  margin: 6vh;
}
a {
  font-weight: 500;
  color: #000000;
  text-decoration: inherit;
}

li{
  list-style: none;
  width: 100%;
}

li a{
  display: flex;
  width: 100%;
  justify-content: center;
}
/*a:hover {*/
/*  color: #000000;*/
/*  background-color: var(--nav-hover-background-color) ;*/
/*}*/

header{
  background-color:var(--header-background-color);
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0;
  height: 8%;
  width: 100%;
  padding-bottom: 1em;
}

.site-heather-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.site-heather-right img{
  width: 10%;
  height: 10%;
}

.site-heather-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.userInfo{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfo img{
  width: 30%;
  height: 30%;
}

.nav-bar{
  width: 100%;
  background-color: var(--nav-background-color);
}
.menu-list{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 2px;
  padding: 2px;
}

.Modal {
  position: absolute; /* Asegúrate de que el modal esté en una posición absoluta */
  top: 50%; /* Centrado vertical */
  left: 50%; /* Centrado horizontal */
  transform: translate(-50%, -50%); /* Compensa el tamaño del modal */
  width: 400px; /* Ancho del modal */
  padding: 20px;
  background-color: #fcf4d9; /* Color de fondo del modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  border-radius: 10px; /* Bordes redondeados */
  z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
  text-align: center;
}

/*!* Estilos para el overlay *!*/
/*.Overlay {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background-color: rgba(0, 0, 0, 0.5); !* Fondo oscuro *!*/
/*  z-index: 999; !* Debe estar justo debajo del modal *!*/
/*}*/

/* Alinear los campos del formulario */
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centrar el contenido horizontalmente */
  position: absolute; /* Asegúrate de que el modal esté en una posición absoluta */
  top: 50%; /* Centrado vertical */
  left: 50%; /* Centrado horizontal */
  transform: translate(-50%, -50%); /* Compensa el tamaño del modal */
  padding: 20px;
  background-color: #fcf4d9; /* Color de fondo del modal */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.70); /* Sombra */
  border-radius: 10px; /* Bordes redondeados */
  max-width: 500px;
  width: 90%; /* Ancho responsivo */
  max-height: 90vh; /* Evitar que se salga de la pantalla en móviles */
}

#formLogin {
  width: 80%;
}

.button-container{
  display: flex;
  flex-direction: row;
}


#formLogin label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

#formLogin input {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#formLogin button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#formLogin button:hover {
  background-color: #333;
}

.inscripciones ul{
  list-style-type: disc; /* Cambiar el tipo de viñeta */
  padding-left: 20px; /* Desplazamiento a la derecha para las viñetas */
  margin: 1rem 0;
}

.inscripciones li{
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
  list-style-type: disclosure-closed;
  text-align: start;
}

.inscripciones ul.custom-bullet{
  list-style-type: none;
}

.inscripciones ul.custom-bullet li::before {
  content: '✔'; /* Aquí puedes cambiar el símbolo por lo que prefieras */
  color: #0056b3; /* Color del ícono */
  font-weight: bold; /* Para hacerlo más visible */
  display: inline-block;
  width: 1.2rem; /* Espacio para el símbolo */
  margin-right: 0.5rem; /* Separación del texto */
}

body {
  margin: 0;
  display: flex;
  /* place-items: center; */
  flex-direction: column ;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

#cici-lema{
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 20px auto;
  letter-spacing: 1px;
  background: linear-gradient(90deg, var(--container-color-green),var(--container-color-blue),var(--container-color-yellow),var(--container-color-purple));
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.sobre-nosotros {
  padding: 40px;
  max-width: 850px;
  margin: 20px auto;
}

.sobre-nosotros h1 {
  font-size: 3rem; /* Tamaño grande para el título */
  font-weight: 700; /* Hace el título más grueso */
  color: #333; /* Color más oscuro para resaltar */
  margin-bottom: 20px; /* Espaciado entre el título y el texto */
}

.sobre-nosotros p {
  font-size: 1.2rem; /* Tamaño de fuente más cómodo de leer */
  line-height: 1.6; /* Aumenta el espaciado entre líneas para mejorar la legibilidad */
  margin-bottom: 30px; /* Añade margen inferior */
}
button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em ;
  font-size: 0.8em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #5e9c768c;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.mision-vision{
  display: flex;
  flex-direction: row;
}

.mision-vision div{
  margin: 5%;
  background-color: var(--container-color-green);
  padding: 35px;
  border-radius: 20px;
}

.sobre-nosotros h3, .our-values h3, .sponsors h3{
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
  background-color: var(--footer-background-color);
  border-radius: 15px;
}

.fade-enter-done{
  /*max-width: 80%;*/
}

.sponsors{
  width: 80%;
margin-top: 50px;
}

.sponsors-logos{
margin-top: 30px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.sponsors-logos img{
max-width: 25%;
max-height: 150px;
}

footer {
  background-color: var(--footer-background-color);
  display: flex;
  /* margin-top: 5em; */
  margin-top: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-right: 0px;
  padding-left: 0px;
}

footer .icon{
  background-color: var(--footer-background-color);
}

footer .redes-sociales{
  height: unset;
}

footer a, footer p {
  font-family: 'Roboto';
  color: #ffffff;
  margin: 2px;

}

footer p {
  text-align: right;
}

.contenedor-infoAdicional {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 20px; /* Espaciado entre tarjetas */
  padding: 1rem;
}

/*@media (prefers-color-scheme: light) {*/
/*  :root {*/
/*    color: #213547;*/
/*    background-color: #ffffff;*/
/*  }*/
/*  a:hover {*/
/*    color: #747bff;*/
/*  }*/
/*  button {*/
/*    background-color: #f9f9f9;*/
/*  }*/
/*}*/


@media (max-width: 530px) {
  /*.menu-list {*/
  /*  display: none;*/
  /*  flex-direction: column;*/
  /*  position: fixed;*/
  /*  justify-content: unset;*/
  /*  top: 0;*/
  /*  left: 0;*/
  /*  width: 200px;*/
  /*  height: 100%;*/
  /*  z-index: 1000;*/
  /*  padding: 20px;*/
  /*  margin: 0;*/
  /*}*/

  .menu-list li a {
    /*display: block;*/
    /*margin-bottom: 20px;*/
    font-size: small;
  }

  .mision-vision{
    flex-direction: column;
  }

  /*#menu-toggle {*/
  /*  display: block;*/
  /*  background-color: #333;*/
  /*  color: white;*/
  /*  padding: 10px;*/
  /*  cursor: pointer;*/
  /*}*/

  /*!* Mostrar el menú al hacer clic en el botón *!*/
  /*.nav-bar.menu-open .menu-list {*/
  /*  display: flex;*/
  /*}*/

  /*button{*/
  /*  font-size: 0.8em;*/
  /*}*/
}

@media (max-width: 900px) {
  .contenedor-infoAdicional {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

