#formulario{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    width: 70%;
    max-width: 800px;
    height: 80%;
    margin: 5%;
}
#formContacto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

#formulario *{
    margin: 10px;
}

input, textArea{
    background-color: #FFFFFF;
    color: black;
    border: 1px solid darkgrey;
    border-radius: 10px;
    width: 90%;
    padding: 4px;
}

.info{
    text-align: start;
}

.contact-container{
    display: flex;

}
.contactImg{
    width: 45%;
    margin-right: 5%;
}

.contact-section{
    align-items: center;
    display: flex;
    flex-direction: column;
}

input{
    height: 4vh;
}

textarea{
    resize: unset;
    height: 10vh;
}

button{
    width: 70%;
    padding: 4px;
}

.redes-sociales{
    display: flex;
    flex-direction: row;
    height: 20vh;
    width: 30%;
    justify-content: center;
}

.icon {
    text-decoration: none;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 14px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.icon img{
    position: relative;
    width: 70%;
}

.icon > i {
    color: #2dd4bf;
    font-size: 1.5rem;
    transition: all 0.2s;
}

.icon:hover > i {
    scale: 1.2;
    color: #f1f1f1;
}

.icon:before {
    background: var(--color);
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    left: -110%;
    top: 80%;
    transform: rotate(45deg);
}

.icon:hover:before {
    animation: slide 0.7s forwards;
}

@keyframes slide {
    50% {
        left: 10%;
        top: -40%;
    }

    100% {
        left: -15%;
        top: -15%;
    }
}

.icon:has(.fa-instagram) {
    --color: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
}

.icon:has(.fa-whatsapp) {
    --color: linear-gradient(45deg, #28881c, #07d925, #f8f8f8);
}

.icon:has(.fa-facebook) {
    --color: linear-gradient(45deg, #1f4dec, #0700ff, #ffffff);
}