.CameraViewer{
    /*display: flex;*/
    /*align-content: center;*/
    /*justify-content: center;*/
    width: 70vw;
    min-height: 80vh;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columnas */
    grid-gap: 20px;
    margin-top: 5vh;
    margin-bottom: 10vh;
    /*align-content: center;*/
}

.CameraViewer td{
    width: 15vw;
    /*height: 15vh;*/
}

.CameraHolder{
    /*width: 100%;*/
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}


@media (max-width: 786px) {
    .CameraViewer{
        grid-template-columns: 1fr;
    }
    .CameraHolder {
        min-height: 300px;
      }

}
